import { Form, FormikProvider } from "formik";
import {
  Checkbox,
  CommonButton,
  DatePicker,
  FormField,
  Select
} from "gov-ua-ui";
import { useMemo } from "react";

import { PrimaryDocumentTypes } from "constant";
import { formatForSelect } from "scenes/Dataset/ducks";

import Autocomplete from "components/Autocomplete/Autocomplete";
import ScrollToFieldError from "components/ScrollToFieldError/ScrollToFieldError";

import styles from "../../search-basis-doc-page.module.scss";

const formatChars = {
  "9": "[0-9]",
  a: "[A-Za-z]",
  "*": "[A-Za-z0-9]",
  я: "[А-Яа-яІіЇїЄє]",
  Я: "[А-Яа-яІіЇїЄє0-9]"
};

const SearchForm = ({
  formik,
  supportedDocumentsList,
  documentType,
  emptyNumberText,
  isPrimary,
  maxDate,
  parentId,
  onAutocompleteChange,
  setDocumentType
}) => {
  let numberFieldMask = "";
  let seriesFieldMask = "";
  let seriesFieldDisabled = false;

  switch (formik.values.documentType.label) {
    case PrimaryDocumentTypes.ttn_wood:
      numberFieldMask = "";
      seriesFieldMask = "яяя";
      seriesFieldDisabled = false;
      break;
    case PrimaryDocumentTypes.md:
      numberFieldMask = "";
      seriesFieldMask = "";
      seriesFieldDisabled = true;
      break;
    case PrimaryDocumentTypes.zn:
      numberFieldMask = "ЯЯЯЯЯЯЯЯ";
      seriesFieldMask = "";
      seriesFieldDisabled = true;
      break;
    case PrimaryDocumentTypes.transfer:
      numberFieldMask = "";
      seriesFieldMask = "яяя";
      seriesFieldDisabled = false;
      break;

    default:
      numberFieldMask = "";
      seriesFieldMask = "";
      seriesFieldDisabled = false;
      break;
  }

  const getForestUserSearchParams = useMemo(() => {
    let params = "";
    if (isPrimary)
      switch (documentType) {
        case PrimaryDocumentTypes.ttn:
          params = "type=forestuser";
          break;
        case PrimaryDocumentTypes.ttn_wood:
          params = "type=forestuser";
          break;
        case PrimaryDocumentTypes.zn:
          params = "type=forestuser";
          break;
        case PrimaryDocumentTypes.transfer:
          params = "type=forestuser";
          break;
        case PrimaryDocumentTypes.md:
          params = "type=foreign";
          break;

        default:
          params = "";
          break;
      }

    return params;
  }, [documentType, isPrimary]);

  const onSelectChange = (option, name) => {
    if (name === "documentType") {
      setDocumentType(option.label);

      if (option.label === PrimaryDocumentTypes.zn) {
        formik.setFieldValue("series", "");
        if (isPrimary) {
          formik.setFieldValue("forestUser", "");
        }
      }
      if (option.label === PrimaryDocumentTypes.md) {
        formik.setFieldValue("series", "");
        formik.setFieldValue("forestUser", "");
      }
      if (option.label === PrimaryDocumentTypes.ttn_wood) {
        formik.setFieldValue("series", formik.values.series.toUpperCase());
        formik.setFieldValue("forestUser", "");
      }

      if (isPrimary) {
        if (option.label === PrimaryDocumentTypes.ttn) {
          formik.setFieldValue("forestUser", "");
        }
        if (option.label === PrimaryDocumentTypes.transfer) {
          formik.setFieldValue("forestUser", "");
        }
      }
    }
    formik.setFieldValue(name, option);
  };

  const onDateChange = (date: Date) => {
    formik.setFieldValue("date", date);
  };

  const handleNumberChange = (val) => {
    if (
      formik.values.documentType.label === PrimaryDocumentTypes.transfer ||
      formik.values.documentType.label === PrimaryDocumentTypes.ttn_wood
    ) {
      formik.setFieldValue("number", val.target.value.trim().toUpperCase());
    } else {
      formik.setFieldValue("number", val.target.value.trim());
    }
  };

  const allowSearchDocumentWithoutNumber = useMemo(() => {
    return (
      documentType === PrimaryDocumentTypes.tn ||
      documentType === PrimaryDocumentTypes.ttn
    );
  }, [documentType]);

  return (
    <FormikProvider value={formik}>
      <Form className={styles["create-primary-document__form-container"]}>
        <div className={styles["create-primary-document__field-row"]}>
          <div className={styles["create-primary-document__small-field-item"]}>
            <Select
              value={formik.values["documentType"]}
              name="documentType"
              options={formatForSelect(supportedDocumentsList)}
              placeholder="Тип документа"
              withFormik
              onSelectChange={onSelectChange}
            />
          </div>
          <div className={styles["create-primary-document__small-field-item"]}>
            <FormField
              name="series"
              placeholder="Серія"
              disabled={seriesFieldDisabled}
              mask={seriesFieldMask}
              maskChar={""}
              maskFormatChars={formatChars}
              onBlur={(val) =>
                documentType === PrimaryDocumentTypes.ttn_wood ||
                documentType === PrimaryDocumentTypes.transfer
                  ? formik.setFieldValue(
                      "series",
                      val.target.value.trim().toUpperCase()
                    )
                  : formik.setFieldValue("series", val.target.value.trim())
              }
              {...(formik.values["withoutNumber"]
                ? { disabled: true, value: "" }
                : {})}
            />
          </div>
          <div className={styles["create-primary-document__small-field-item"]}>
            <FormField
              name="number"
              mask={numberFieldMask}
              maskFormatChars={formatChars}
              maskChar={""}
              placeholder="Номер"
              onChange={handleNumberChange}
              onBlur={(val) =>
                formik.setFieldValue("number", val.target.value.trim())
              }
              {...(formik.values["withoutNumber"]
                ? {
                    disabled: true,
                    value: emptyNumberText,
                    placeholder: "Без номеру"
                  }
                : {})}
            />
            {!isPrimary && (
              <Checkbox
                name={"withoutNumber"}
                checked={formik.values["withoutNumber"]}
                title="Документ не має номеру"
                disabled={!allowSearchDocumentWithoutNumber}
                className={styles["item-checkbox"]}
                onClick={(e) => {
                  formik.setFieldValue("withoutNumber", e.target.value);
                }}
              />
            )}
          </div>
          <div className={styles["create-primary-document__small-field-item"]}>
            <DatePicker
              date={formik.values["date"]}
              name="date"
              placeholder="Дата видачі"
              withFormik
              maxDate={maxDate}
              onChange={onDateChange}
            />
          </div>
        </div>
        <div className={styles["create-primary-document__field-row"]}>
          <div className={styles["create-primary-document__medium-field-item"]}>
            <Autocomplete
              name="forestUser"
              value={formik.values["forestUser"]}
              placeholder={isPrimary ? "Лісокористувач" : "Продавець"}
              entity="organizations"
              params={getForestUserSearchParams}
              onChange={onAutocompleteChange}
              disabled={!isPrimary && !!parentId}
            />
          </div>
          <div className={styles["create-primary-document__small-field-item"]}>
            <Autocomplete
              name="forestUserEdrpo"
              value={formik.values["forestUserEdrpo"]}
              placeholder={"Код за ЄДРПОУ"}
              entity="organizations"
              params={getForestUserSearchParams}
              labelFieldName="edrpou"
              onChange={onAutocompleteChange}
              disabled={!isPrimary && !!parentId}
            />
          </div>
        </div>
        {formik.values["forestUserLocation"] && (
          <div
            className={styles["create-primary-document__location-container"]}
          >
            <h4 className={styles["create-primary-document__location-label"]}>
              Місцезнаходження
            </h4>
            <h4 className={styles["create-primary-document__location-text"]}>
              {formik.values["forestUserLocation"]}
            </h4>
          </div>
        )}
        <div className={styles["create-primary-document__field-row"]}>
          <div className={styles["create-primary-document__medium-field-item"]}>
            <Autocomplete
              name="counterparty"
              value={formik.values["counterparty"]}
              placeholder={"Контрагент"}
              entity="organizations"
              onChange={onAutocompleteChange}
            />
          </div>
          <div className={styles["create-primary-document__small-field-item"]}>
            <Autocomplete
              name="counterpartyEdrpo"
              value={formik.values["counterpartyEdrpo"]}
              placeholder={"Код за ЄДРПОУ"}
              entity="organizations"
              labelFieldName="edrpou"
              onChange={onAutocompleteChange}
            />
          </div>
        </div>
        {formik.values["counterpartyLocation"] && (
          <div
            className={styles["create-primary-document__location-container"]}
          >
            <h4 className={styles["create-primary-document__location-label"]}>
              Місцезнаходження
            </h4>
            <h4 className={styles["create-primary-document__location-text"]}>
              {formik.values["counterpartyLocation"]}
            </h4>
          </div>
        )}
        <div
          className={styles["create-primary-document__form-controls-wrapper"]}
        >
          <CommonButton label="Пошук" type="submit" />
        </div>
      </Form>
      <ScrollToFieldError />
    </FormikProvider>
  );
};
export default SearchForm;
