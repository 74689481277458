import { useFormik } from "formik";
import { Alert, CommonButton, Title } from "gov-ua-ui";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import {
  NotRequireSeriesTypes,
  PrimaryDocumentTypes,
  getDocTypeAlias
} from "constant";
import features from "features";
import { formatDate, getChainNumber, parseResErrors } from "helpers";
import { getSearchTitle } from "helpers/bread-crumbs";
import { IRootState } from "reducer";
import { IDatasetState } from "scenes/Dataset/ducks";
import { IApplicationState } from "scenes/subject/applications/ApplicationPage/ducks";
import { ISubjectDocs } from "../../ducks";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import { renderError } from "components/ResponseErrors/renderError";
import FormLayout from "components/layouts/FormLayout/FormLayout";

import NoDocumentsFound from "./components/NoDocumentsFound/NotFounded";
import SearchForm from "./components/SearchForm/SearchForm";
import SearchResult from "./components/SearchResults/SearchResults";
import styles from "./search-basis-doc-page.module.scss";

type FormValues = {
  documentType: {
    value: string;
    label: string;
  };
  series: string;
  number: string;
  withoutNumber: boolean;
  date?: Date;
  forestUser: {
    value: string;
    label: string;
  };
  forestUserEdrpo: {
    value: string;
    label: string;
  };
  forestUserLocation: string;
  counterparty: {
    value: string;
    label: string;
  };
  counterpartyEdrpo: {
    value: string;
    label: string;
  };
  counterpartyLocation: string;
};

const initialValues: FormValues = {
  documentType: {
    value: "",
    label: ""
  },
  series: "",
  number: "",
  withoutNumber: false,
  forestUser: {
    value: "",
    label: ""
  },
  forestUserEdrpo: {
    value: "",
    label: ""
  },
  forestUserLocation: "",
  counterparty: {
    value: "",
    label: ""
  },
  counterpartyEdrpo: {
    value: "",
    label: ""
  },
  counterpartyLocation: "",
  date: null
};

const numberValidation: Record<string, { check: RegExp; msg: string }> = {
  // [PrimaryDocumentTypes.ttn_wood]: {
  //   check: /^\d{6}$/,
  //   msg: "Має бути 6 цифр"
  // },
  // [PrimaryDocumentTypes.transfer]: { check: /^\d{6}$/, msg: "Має бути 6 цифр" }
};

const buildNumberValidation = (val: string, schema: Yup.StringSchema) => {
  const validation = numberValidation[val];
  return validation
    ? schema.matches(validation.check, {
        message: validation.msg,
        excludeEmptyString: true
      })
    : schema;
};

const emptyNumberText = "б/н";

const SearchBasisDocPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resultsRef = useRef(null);
  const [searchParams] = useSearchParams();

  const { applicationId, chain } = useParams<{
    applicationId?: string;
    chain: string;
  }>();
  const [isPrimary] = useState(chain === "primary");
  const parentId = parseInt(searchParams.get("parent"));

  const { supportedDocumentsList, fetchingSupportedDocumentsListLoading } =
    useSelector<IRootState, IDatasetState>((state) => state.dataset);
  const { fetchingSearchDocsLoading, foundDocs } = useSelector<
    IRootState,
    ISubjectDocs
  >((state) => state.subjectDocs);
  const { application } = useSelector<IRootState, IApplicationState>(
    (state) => state.application
  );
  const [maxDate, setMaxDate] = useState(new Date(application?.createdAt));

  const [unionDocs, setUnionDocs] = useState([]);
  const [modifiedSearch, setModifiedSearch] = useState(false);
  const [searchWithoutNumber, setSearchWithoutNumber] = useState(false);

  const [documentType, setDocumentType] = useState<string>("");

  const notRequireSeriesDocumentTypes = Object.values(NotRequireSeriesTypes);

  useEffect(() => {
    if (applicationId) {
      dispatch(
        features.application.actions.fetchApplicationRequest({
          params: { uuid: applicationId },
          onSuccess: (res) => {
            setMaxDate(new Date(res?.createdAt));

            const isWoodCard = res.useWoodCard;
            const fields = {
              type: isPrimary ? "primal" : "transition",
              ...(isWoodCard && { validationForApplication: applicationId })
            };

            dispatch(
              features.dataset.actions.fetchSupportedDocumentsListRequest({
                fields
              })
            );
          }
        })
      );
    }
  }, [applicationId, dispatch, isPrimary]);

  useEffect(() => {
    return () => {
      dispatch(features.application.actions.clearApplication());
      dispatch(features.dataset.actions.clearSupportedDocumentsList());
      dispatch(features.subjectDocs.actions.clearFoundDocs());
    };
  }, [dispatch]);

  useEffect(() => {
    const data = application?.basisDocuments.find(
      (item) => parseInt(item.id) === parentId
    );
    const externalId = data?.recipientOrganizationExternalId;
    if (externalId) onAutocompleteChange({ value: externalId }, "forestUser");
  }, [application, searchParams]);

  const onFormSubmit = (values) => {
    const documentTypeAlias = supportedDocumentsList.find(
      (el) => el.uuid === values.documentType.value
    ).alias;

    const fields = {
      number: values.number,
      date: values.date,
      documentTypeAlias: documentTypeAlias,
      forestUserId: values.forestUser?.value,
      counterPartyId: values.counterparty?.value,
      ...(isPrimary
        ? {}
        : {
            withoutNumber: values.withoutNumber,
            number: values.withoutNumber ? emptyNumberText : values.number
          }),
      series: values.withoutNumber ? null : values.series || null
    };

    dispatch(
      features.subjectDocs.actions.fetchSearchDocsRequest({
        fields,
        onSuccess: () => {
          setTimeout(() => {
            const { offsetTop } = resultsRef.current;
            window.scrollTo({ top: offsetTop - 150, behavior: "smooth" });
          }, 100);

          setUnionDocs([]);
          formikUnion.setValues([]);
          setModifiedSearch(false);
          values.withoutNumber
            ? setSearchWithoutNumber(true)
            : setSearchWithoutNumber(false);
        },
        onError: (e) => {
          setModifiedSearch(false);
          parseResErrors({
            setFieldError: formik.setFieldError,
            errorsRes: e,
            fields: formik.values,
            fieldsAliases: {
              sourceOrganizationExternalId: "forestUser",
              recipientOrganizationExternalId: "counterparty"
            },
            onUnknownErrors: (unknownErrors) => {
              toastr.error("Помилка", {
                component: renderError(unknownErrors)
              });
            }
          });
        }
      })
    );
  };

  const [isCreatingNewDocument, setIsCreatingNewDocument] = useState(false);

  const documentSeriesValidation = () => {
    const requiredSeriesError = Yup.string()
      .when("withoutNumber", ([val]) => {
        if (val) return Yup.string();
      })
      .required("Введіть серію");
    if (
      isCreatingNewDocument &&
      !notRequireSeriesDocumentTypes.includes(documentType)
    ) {
      return requiredSeriesError;
    } else {
      return Yup.string()
        .when("withoutNumber", ([val]) => {
          if (val) return Yup.string();
        })
        .when("documentType.label", ([val], schema) => {
          return val?.includes(PrimaryDocumentTypes.ttn_wood) ||
            val?.includes(PrimaryDocumentTypes.transfer)
            ? schema
                .required("Введіть серію")
                .min(3, "Має бути 3 літери")
                .max(3, "Має бути 3 літери")
            : schema;
        });
    }
  };

  const validationSchema = Yup.object().shape({
    documentType: Yup.object().shape({
      value: Yup.string().required("Виберіть тип документа")
    }),
    series: documentSeriesValidation(),
    number: Yup.string()
      .when("withoutNumber", ([val]) => {
        if (val) return Yup.string();
      })
      .required("Введіть номер")
      .when("documentType.label", ([val], schema) => {
        if (PrimaryDocumentTypes.md === val)
          return schema.max(30, "Максимальна кількість 30 символів");

        return buildNumberValidation(val, schema);
      }),
    date: Yup.date().required("Введіть дату"),
    forestUser: Yup.object().shape({
      value: Yup.string().required("Введіть лісокористувача")
    }),
    forestUserEdrpo: Yup.object().shape({
      value: Yup.string().required("Введіть код ЄДРПОУ")
    }),
    counterparty: Yup.object()
      .when("withoutNumber", ([val]) => {
        if (!val && !isCreatingNewDocument) return Yup.object();
      })
      .shape({
        value: Yup.string().required("Введіть контрагента")
      }),
    counterpartyEdrpo: Yup.object()
      .when("withoutNumber", ([val]) => {
        if (!val && !isCreatingNewDocument) return Yup.object();
      })
      .shape({
        value: Yup.string().required("Введіть код ЄДРПОУ")
      })
  });

  const formik = useFormik<FormValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
    onSubmit: onFormSubmit
  });
  const onFormUnionSubmit = (val) => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "CONFIRM_ACTION",
        modalProps: {
          title: "Об’єднати обрані",
          onAccept: () => {
            const unionDocsData = [];
            val.forEach((item, index) => {
              if (item) unionDocsData.push(foundDocs[index].id);
            });
            setUnionDocs(unionDocsData);
            onCreateDocumentClick();
          }
        }
      })
    );
  };
  const formikUnion = useFormik({
    initialValues: [],
    enableReinitialize: true,
    onSubmit: onFormUnionSubmit
  });

  useEffect(() => {
    if (formikUnion?.values?.filter((item) => item).length === 1) {
      const checkIndex = formikUnion?.values?.findIndex((item) => item);
      if (checkIndex > -1) {
        const { counterParty: label, counterPartyExternalId: value } =
          foundDocs[checkIndex];
        const option = {
          label,
          value
        };
        onAutocompleteChange(option, "counterparty", true);
      }
    }
  }, [formikUnion?.values, foundDocs]);

  const onAutocompleteChange = useCallback(
    (option, name, byUnion?) => {
      const changedName = name.replace("Edrpo", "");

      if (!byUnion) {
        setModifiedSearch(true);
        if (formik.values.documentType.label === PrimaryDocumentTypes.zn)
          formikUnion.resetForm();
      }

      if (option.value) {
        dispatch(
          features.application.actions.fetchSelectedOrganizationsListRequest({
            params: {
              externalId: option.value
            },
            onSuccess: (response) => {
              formik.setFieldValue(changedName, {
                value: response.externalId,
                label: response.name
              });

              formik.setFieldValue(`${changedName}Edrpo`, {
                value: response.externalId,
                label: response.edrpou?.toString()
              });
              formik.setFieldValue(`${changedName}Location`, response.country);

              // TODO need update of this fix
              if (changedName !== "counterparty")
                setTimeout(() => {
                  formik.validateField(changedName).catch(() => {});
                  formik.validateField(`${changedName}Edrpo`).catch(() => {});
                }, 10);
            }
          })
        );
      } else {
        formik.setFieldValue(changedName, {
          value: "",
          label: ""
        });
        formik.setFieldValue(`${changedName}Edrpo`, {
          value: "",
          label: ""
        });
        formik.setFieldValue(`${changedName}Location`, "");
      }
    },
    [dispatch, formik]
  );

  const getLinkToCreatingDocument = useCallback(
    (type, applicationId, documentId) => {
      let documentName = "";

      switch (type) {
        case PrimaryDocumentTypes.tn:
          documentName = "TN";
          break;
        case PrimaryDocumentTypes.ttn:
          documentName = "TTN";
          break;
        case PrimaryDocumentTypes.zn:
          documentName = "ZN";
          break;
        case PrimaryDocumentTypes.md:
          documentName = "MD";
          break;
      }

      let link = `/personal/applications/${applicationId}/documents/${documentName}/${documentId}`;

      if (parentId) {
        link += `?parent=${parentId}`;
      }

      return link;
    },
    []
  );

  const closePreloaderModal = useCallback(() => {
    dispatch(
      features.modal.actions.hideModal({
        modalType: "PRELOADER"
      })
    );
  }, []);

  const onCreateDocumentClick = useCallback(() => {
    setIsCreatingNewDocument(true);
  }, []);
  const onCreateZNDocumentClick = useCallback(() => {
    setUnionDocs([]);
    onCreateDocumentClick();
  }, [onCreateDocumentClick]);

  useEffect(() => {
    if (isCreatingNewDocument) {
      formik.validateForm().then((val) => {
        if (isEmpty(val)) {
          dispatch(
            features.modal.actions.showModal({
              modalType: "PRELOADER",
              modalProps: {
                title: `Створюється ${
                  isPrimary ? "первинний" : "перехідний"
                } документ підстави єСертифіката`,
                loading: true
              }
            })
          );
          const {
            documentType,
            series,
            number,
            date,
            forestUser,
            counterparty,
            withoutNumber
          } = formik.values;
          dispatch(
            features.subjectDocs.actions.createDocRequest({
              fields: {
                validateForApplication: applicationId,
                validateForParentId: parentId.toString(),
                series: withoutNumber ? null : series || null,
                number: withoutNumber ? emptyNumberText : number,
                date,
                sourceOrganizationExternalId: parseInt(forestUser.value),
                recipientOrganizationExternalId: parseInt(counterparty.value),
                documentTypeAlias: getDocTypeAlias(documentType.label),
                validateForChainNumber: getChainNumber(
                  application.basisDocuments
                ),
                byUnion: unionDocs?.length > 0
              },
              onSuccess: (response) => {
                if (unionDocs?.length > 0) {
                  const deleteDocsId = foundDocs
                    .filter((doc) => doc.id && !unionDocs.includes(doc.id))
                    .map((doc) => doc.id);

                  dispatch(
                    features.subjectDocs.actions.fetchDocRequest({
                      params: {
                        children: unionDocs
                      },
                      fields: {
                        validateForApplication: applicationId,
                        validateForParentId: parentId.toString(),
                        zn: false
                      },
                      onSuccess: (res) => {
                        dispatch(
                          features.subjectDocs.actions.unionDocRequest({
                            fields: {
                              parentUuid: response.uuid,
                              childrenUuids: res
                            },
                            onSuccess: () => {
                              if (deleteDocsId.length > 0)
                                dispatch(
                                  features.subjectDocs.actions.removeUnusedZSNDocsRequest(
                                    {
                                      fields: deleteDocsId,
                                      onSuccess: () => {
                                        closePreloaderModal();
                                        navigate(
                                          getLinkToCreatingDocument(
                                            documentType.label,
                                            applicationId,
                                            response.uuid
                                          )
                                        );
                                      },
                                      onError: () => {
                                        closePreloaderModal();
                                      }
                                    }
                                  )
                                );
                              else {
                                closePreloaderModal();
                                navigate(
                                  getLinkToCreatingDocument(
                                    documentType.label,
                                    applicationId,
                                    response.uuid
                                  )
                                );
                              }
                            },
                            onError: () => {
                              closePreloaderModal();
                            }
                          })
                        );
                      },
                      onError: () => {
                        dispatch(features.modal.actions.hideModal());
                      }
                    })
                  );
                } else {
                  closePreloaderModal();
                  if (response && response.uuid) {
                    navigate(
                      getLinkToCreatingDocument(
                        documentType.label,
                        applicationId,
                        response.uuid
                      )
                    );
                  }
                }
              },
              onError: (e) => {
                parseResErrors({
                  setFieldError: formik.setFieldError,
                  errorsRes: e,
                  fields: formik.values,
                  fieldsAliases: {
                    sourceOrganizationExternalId: "forestUser",
                    recipientOrganizationExternalId: "counterparty"
                  }
                });
                closePreloaderModal();
              }
            })
          );
        }
        formik.setStatus("scrollToForm");
        setIsCreatingNewDocument(false);
      });
      setIsCreatingNewDocument(false);
    }
  }, [
    applicationId,
    closePreloaderModal,
    dispatch,
    getLinkToCreatingDocument,
    isCreatingNewDocument,
    unionDocs,
    navigate
  ]);

  const pageTitle = useMemo(() => {
    return getSearchTitle(isPrimary, application?.useWoodCard);
  }, [application?.useWoodCard, isPrimary]);

  const breadcrumbs = useMemo(
    () => [
      {
        text: "Заявка на видачу сертифіката",
        to: `/personal/applications/${applicationId}`
      },
      {
        text: pageTitle,
        to: "/"
      }
    ],
    [applicationId, pageTitle]
  );

  const getIsSearchNotEqual = useMemo(() => {
    if (
      documentType === PrimaryDocumentTypes.ttn_wood ||
      documentType === PrimaryDocumentTypes.transfer
    )
      return false;

    let isEqual = false;

    if (foundDocs?.length > 0)
      foundDocs.forEach((item) => {
        if (
          formatDate(item.date).date === formatDate(formik.values.date).date &&
          (item.number === formik.values.number ||
            formik.values.withoutNumber) &&
          (item.series === formik.values.series || formik.values.withoutNumber)
        )
          isEqual = true;
      });
    else return false;

    return !isEqual;
  }, [
    documentType,
    formik.values.date,
    formik.values.number,
    formik.values.withoutNumber,
    foundDocs
  ]);

  useEffect(() => {
    setModifiedSearch(true);
  }, [
    formik.values.documentType,
    formik.values.number,
    formik.values.date,
    formik.values.forestUser
  ]);

  return (
    <FormLayout breadcrumbs={breadcrumbs}>
      <div className={styles["create-primary-document"]}>
        <PreloaderWrapper
          loading={
            fetchingSearchDocsLoading || fetchingSupportedDocumentsListLoading
          }
          withModal
        >
          <Title size={32} className={styles["create-primary-document__title"]}>
            {pageTitle}
          </Title>
          <SearchForm
            formik={formik}
            supportedDocumentsList={supportedDocumentsList}
            documentType={documentType}
            emptyNumberText={emptyNumberText}
            isPrimary={isPrimary}
            maxDate={maxDate}
            parentId={parentId}
            onAutocompleteChange={onAutocompleteChange}
            setDocumentType={setDocumentType}
          />

          <div ref={resultsRef}></div>
          <Title
            size={20}
            className={styles["create-primary-document__search-results-title"]}
          >
            Результат пошуку
          </Title>
          {foundDocs === null && (
            <Alert
              type="warning"
              withIcon
              className={styles["create-primary-document__notification"]}
            >
              Заповніть дані для пошуку документу
            </Alert>
          )}
          {foundDocs?.length > 0 && searchWithoutNumber && (
            <Alert
              type="warning"
              withIcon
              className={styles["create-primary-document__notification"]}
            >
              Використовується документ без номеру
            </Alert>
          )}

          {foundDocs && (
            <SearchResult
              formikUnion={formikUnion}
              foundDocs={foundDocs}
              getDocTypeAlias={getDocTypeAlias}
              onCreateZNDocumentClick={onCreateZNDocumentClick}
              modifiedSearch={modifiedSearch}
              parentId={parentId}
              counterparty={formik.values.counterparty.label}
            />
          )}

          {getIsSearchNotEqual &&
            !foundDocs.find(
              (item) => getDocTypeAlias(item.typeName) === "zsn"
            ) && (
              <>
                <div className={styles["create-primary-document__container"]}>
                  <CommonButton
                    label="Створити новий"
                    onClick={onCreateDocumentClick}
                    disabled={modifiedSearch}
                  />
                </div>
              </>
            )}
          {foundDocs !== null && !foundDocs?.length && (
            <NoDocumentsFound
              documentType={documentType}
              onCreateDocumentClick={onCreateDocumentClick}
              modifiedSearch={modifiedSearch}
            />
          )}
        </PreloaderWrapper>
      </div>
    </FormLayout>
  );
};

export default SearchBasisDocPage;
